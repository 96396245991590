module Home.View

open Fable.Core
open Fable.Core.JsInterop
open Fable.React
open Fable.React.Props
open Types
open Common
open Global
open System.Globalization
open Imports
open Utils
open ViewComponents
open Browser

let connect (web3 :UseWeb3ReactHook) dispatch =
    match web3.active with
    | false ->
        [div [ClassName "connect"]
            [ConnectButton { className = "button big-connect"; children = [str "Connect"] }
             p [] [str "Connect your account to edit your page"]]
         ]
    | true ->
        []

let Carousel =
    FunctionComponent.Of ((fun () ->
        let index = Hooks.useState 0
        let elems =
            let getElem (traitValues :string[]) =
                div [ClassName "nft-detail"]
                    [img [Src "/img/image_missing_borderless.svg"]
                     div [ClassName "traits"]
                        [p [ClassName "trait"] [str "Background"]
                         p [ClassName "trait-value"] [str traitValues.[0]]
                         p [ClassName "trait"] [str "Hair"]
                         p [ClassName "trait-value"] [str traitValues.[1]]
                         p [ClassName "trait"] [str "Eyes"]
                         p [ClassName "trait-value"] [str traitValues.[2]]
                         p [ClassName "trait"] [str "Facial Hair"]
                         p [ClassName "trait-value"] [str traitValues.[3]]
                         p [ClassName "trait"] [str "Accessory"]
                         p [ClassName "trait-value"] [str traitValues.[4]]
                         p [ClassName "trait"] [str "Blockchain Power"]
                         p [ClassName "trait-value"] [str traitValues.[5]]]]
            [|
                getElem [| "Transparent Rainbow"; "Untied Bow"; "Night-vision Shades"; "Hairless Beard"; "Silent Headphones"; "Reorder Blocks" |]
                getElem [| "5-Sided Squares"; "4-Dimensional Hat"; "Invisible Eyepatch"; "Foot-long Stubble"; "Static Watch"; "Infinite Hash Power" |]
                getElem [| "1-Colour Gradient"; "Invisible Bangs"; "Opaque Glasses"; "None with Some"; "Full-body Bikini"; "Double Spend" |]
                getElem [| "Pointy Circles"; "Shaved Ponytail"; "Three-eyed Cyclops"; "Hairless Beard"; "Bottomless Handbag"; "Universal Signature" |]
                getElem [| "Sky-blue Yellow"; "Short-backed Mullet"; "Night-vision Shades"; "Invisible Moustache"; "Fishnet Umbrella"; "Double Spend" |]
            |]
        let prev = if index.current = 0 then elems.Length - 1 else index.current - 1
        let next = if index.current = elems.Length - 1 then 0 else index.current + 1
        let elemsWrapped =
            elems |> Array.mapi (fun i elem ->
                div [classList ["active", i = index.current; "prev", i = prev; "next", i = next]] [elem])
        Hooks.useEffect (fun () ->
            window.setTimeout((fun () ->
                if index.current = elems.Length - 1 then index.update 0 else index.update (index.current + 1)
                ), 3000) |> ignore)
        div [ClassName "carousel-wrapper"]
            [div [ClassName "carousel"] elemsWrapped]))

let HomePage =
    FunctionComponent.Of ((fun (props : {| dispatch: Msg -> unit |}) ->
        div [ClassName "has-text-centered container main"]
            [img [Src "/img/image_missing_borderless.svg"]
             h1 [] [str "Offchain NFT"]
             h2 [] [str "A limited collection of 2040 totally unique on-chain NFTs, passed through an advanced smart contract algorithm to simulate the visual results of most off-chain NFTs in the year 2040"]
             div [ClassName "hero"]
                (Array.init 50 (fun _ -> div [] [img [Src "/img/image_missing_borderless.svg"]]))
             div [ClassName "minting"; Id "minting"]
                [h3 [] [str "Minting"]
                 img [Src "/img/minting.svg"]
                 p [] [str "To mint an Offchain NFT, send any NFT of your choice (you can use "; a [Href "https://https://opensea.io/"; Target "_blank"] [str "OpenSea"]; str " or "; a [Href "https://www.myetherwallet.com/"; Target "_blank"] [str "MyEtherWallet"]; str " for example) to:"]
                 p [] [b [] [str "offchainnft.eth"]; str " ("; a [Href "https://etherscan.io/address/0x39866b07989c680278eaeebebbe41b062342de5a"; Target "_blank"] [str "0x39866b07989c680278eaeebebbe41b062342de5a"]; str ")"]
                 ]
             div [ClassName "art"; Id "art"]
                [h3 [] [str "The Art"]
                 Carousel ()]
             div [ClassName "faq"; Id "faq"]
                [h3 [] [str "FAQ"]
                 p [ClassName "question"] [str "How much does it cost to mint?"]
                 p [ClassName "answer"] [str "Offchain NFTs are totally free, but you must burn a (preferably off-chain) NFT to mint one. It can be any NFT you like, but you will gain more respect from the community the more noteworthy your burn."]
                 p [ClassName "question"] [str "How do I mint one?"]
                 p [ClassName "answer"] [str "Send any NFT you like to "; b [] [str "offchainnft.eth"]; str " ("; a [Href "https://etherscan.io/address/0x39866b07989c680278eaeebebbe41b062342de5a"; Target "_blank"] [str "0x39866b07989c680278eaeebebbe41b062342de5a"]; str ") and an Offchain NFT will be sent back to you in the same transaction. To send an NFT you can use "; a [Href "https://opensea.io/"; Target "_blank"] [str "OpenSea"]; str " or "; a [Href "https://www.myetherwallet.com/"; Target "_blank"] [str "MyEtherWallet"]]
                 p [ClassName "question"] [str "Is this a scam?"]
                 p [ClassName "answer"] [str "Absolutely not. The NFT you send cannot be accessed by us and there are no fees involved anywere. The smart contract logic is public and is available "; a [Href "https://etherscan.io/address/0x39866b07989c680278eaeebebbe41b062342de5a"; Target "_blank"] [str "here"]; str ". If you are worried, send a very low value NFT."]
                 p [ClassName "question"] [str "Why did you create this?"]
                 p [ClassName "answer"] [
                    str "We created this to raise awareness about on-chain NFTs and to have some fun. We are a group of on-chain NFT enthusiasts and all have our own collections: "
                    ul []
                        [li [] [a [Href "https://blockheads.network"; Target "_blank"] [str "Blockheads"]]
                         li [] [a [Href "https://goodblocks.io"; Target "_blank"] [str "goodblocks"]]
                         li [] [a [Href "https://www.mutytes.com"; Target "_blank"] [str "Mutytes"]]]]]
             div [ClassName "roadmap"; Id "roadmap"]
                [h3 [] [str "Roadmap"]
                 p [ClassName "roadmap-icon"] [i [ClassName "mdi mdi-circle-multiple"] []]
                 p [ClassName "roadmap-title"] [str "Offchain Coin"]
                 p [ClassName "roadmap-desc"] [str "We will release a custom token where the balances of that token are held entirely off-chain, stored in individual wallets. Be your own bank and print as much of the coin as you want should you get in financial difficulty."]
                 p [ClassName "roadmap-icon"] [i [ClassName "mdi mdi-account-group"] []]
                 p [ClassName "roadmap-title"] [str "Offchain Social Network"]
                 p [ClassName "roadmap-desc"] [str "We will release a social networking application that solves the content moderation problem by applying our custom 100% censorship of content algorithm."]
                 p [ClassName "roadmap-icon"] [i [ClassName "mdi mdi-virtual-reality"] []]
                 p [ClassName "roadmap-title"] [str "Offchain Metaverse"]
                 p [ClassName "roadmap-desc"] [str "Every Offchain NFT will receive a parcel of land in an off-chain metaverse. We will use our algorithms to simulate a custom virtual world as it would be in 2040 for a centralised server that went offline in 2034."]
                 p [ClassName "roadmap-icon"] [i [ClassName "mdi mdi-rocket-launch"] []]
                 p [ClassName "roadmap-title"] [str "Moonshot - Offchain Blockchain"]
                 p [ClassName "roadmap-desc"] [str "We will launch our own blockchain. In order to maximise performance (we care about scalability to tackle real world problems) we will have a single validator. We aim to process at least 1000000 transactions per second using this model and will therefore become the premier L1."]
                 ]
             footer []
                [div [ClassName "social-icons"]
                    [div [] [a [Href "https://opensea.io/collection/offchain-nft"; Target "_blank"] [img [Src "/img/opensea-custom.svg"]]]
                     div [] [a [Href "https://twitter.com/OffchainNFT"; Target "_blank"] [img [Src "/img/twitter-custom.svg"]]]
                     div [] [a [Href "https://discord.gg/GNz5vdvHaD"; Target "_blank"] [img [Src "/img/discord-custom.svg"]]]]]
             ]
   ), "HomePage", equalsButFunctions)

let root model common dispatch =
    div []
        [nav [ClassName "navbar is-fixed-top"; Role "navigation"; AriaLabel "main navigation"]
            [div [ClassName "container"]
                [div [ClassName "navbar-brand"] [a [ClassName "navbar-item"; Href "/"] [img [Src "/img/image_missing_borderless.svg"]; str " Offchain NFT"]]
                 div [ClassName "navbar-menu"]
                    [div [ClassName "navbar-end"]
                        [a [ClassName "navbar-item"; Href "#art"] [str "The Art"]
                         a [ClassName "navbar-item"; Href "#faq"] [str "FAQ"]
                         a [ClassName "navbar-item"; Href "#roadmap"] [str "Roadmap"]]]]]
         HomePage {| dispatch = dispatch |}]
