module ViewComponents

open Fable.Core
open Fable.Core.JsInterop
open Fable.React
open Fable.React.Props
open Common
open System.Globalization
open Imports.ReactMarkdown
open Imports.ReactJazzicon
open Imports
open Utils
open Feliz.UseDeferred
open Browser.Dom
open Browser.Types
open System.Collections.Generic
open Web3TreeDapp

// Page Modal
// --------
type PageModalProps =
    { active: bool; children: ReactElement list }

let PageModal =
    FunctionComponent.Of ((fun (props :PageModalProps) ->
        div [classBaseList "page-modal" ["active", props.active]] props.children
    ), "PageModal", equalsButFunctions)

// Connect Modal
// -----------

type ConnectModalControls =
    { show: unit -> unit
      hide: unit -> unit }

let connectModalControlsContext :IContext<ConnectModalControls option> = createContext None

type ConnectModalProps =
    { active: bool }

let ConnectModal =
    FunctionComponent.Of((fun (props :ConnectModalProps) ->
        let web3 = useWeb3React()
        let modalControls :ConnectModalControls option = Hooks.useContext connectModalControlsContext
        PageModal
            { active = props.active
              children =
                [div [ClassName "connect-modal"]
                    [h1 [] [span [] [str "Connect a wallet"]; a [OnClick (fun _ -> modalControls.Value.hide())] [mdiIcon "close"]]
                     button [OnClick (fun _ -> web3.activate(Injected); modalControls.Value.hide()); ClassName "button"] [div [] [str "Metamask"]; div [] [img [Src "/img/metamask.png"]]]
                     button [OnClick (fun _ -> web3.activate(WalletConnect); modalControls.Value.hide()); ClassName "button"] [div [] [str "Wallet Connect"]; div [] [img [Src "/img/walletConnect.svg"]]]
                     button [OnClick (fun _ -> web3.activate(CoinbaseWallet); modalControls.Value.hide()); ClassName "button"] [div [] [str "Coinbase Wallet"]; div [] [img [Src "/img/coinbaseWallet.svg"]]]]]}
    ), "ConnectModal", equalsButFunctions)

// Connect Button
// ---------
type ConnectButtonProps =
    { className: string; children: ReactElement list }

let ConnectButton =
    FunctionComponent.Of((fun (props :ConnectButtonProps) ->
        let modalControls :ConnectModalControls option = Hooks.useContext connectModalControlsContext
        button [OnClick (fun _ -> match modalControls with | None -> () | Some controls -> controls.show()); ClassName props.className] (props.children)
    ), "ConnectButton", equalsButFunctions)
