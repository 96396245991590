module Common

open Fable.Core
open Fable.Core.JsInterop
open Browser.Types
open Fable.React
open Imports

type DappTxResult<'msg> =
    | Response of 'msg * TransactionResponse
    | Confirmed of 'msg * ConfirmedTransactionResponse
    | UserCancelled of 'msg
    | UnknownError of 'msg * exn

// --------------

type AsyncResult<'t> =
    | Resolved of 't
    | Unresolved

type StorageType =
    | Unknown
    | IPFS
    | OnChain

type ImageFormat =
    | Svg
    | Jpg
    | Gif
    | Png
    | WebP
    | UnknownFormat

type VideoFormat = Mp4

type NFTDisplayRecord =
    { display: string
      format: string
      storage: string
      url: string
      hasPreview: bool
      hasW388: bool }

type NFTOwners =
    { owners: string[] }

type NFT =
    { token_id: string
      token_uri: string
      token_address: string
      block_number: string
      block_number_minted: string
      name: string
      symbol: string
      metadata: string
      display: NFTDisplayRecord
      media_raw: string
      title: string
      description: string }

type Like =
    { userAddress: string
      userDisplay: string
      userImage: string
      userUrl: string
      text: string
      timestamp: int64
      blockNumber: int64
      transactionHash: string }

type LikeSlim =
    { text: string }

type ProfileInfo =
    { displayName: string
      image: string }

type NFTResult =
    { index: int
      nft: NFT
      likes: LikeSlim[]
      likeCount: int
      rarityRanking: float }

type NftAttribute =
    { traitType: string
      value: string
      displayType: string }

type NFTResultFull =
    { nft: NFT
      attributes: NftAttribute[] }

type NFTCollection =
    { token_address: string 
      name: string
      symbol: string
      ranking: int
      description: string
      bannerUrl: string
      imageUrl: string }

type NFTCollectionResult =
    { collection: NFTCollection
      nfts: NFTResult[] }

type PagedResult<'t> =
    { results: 't[]
      cursor: string }

type NFTDetails =
    { nft: NFT }

type TreeLink =
    { name: string
      url: string }

type TreeEditLink =
    { key: string
      index: int
      editing: bool
      name: string
      url: string
      nameField: string
      urlField: string }

type TreeNft =
    { token_address: string
      token_id: string
      display: bool }

type TreeData =
    { profileNft: (string * string) option
      name: string
      bio: string
      links: TreeLink[]
      nfts: Map<string * string, bool>
      colors: string[]
      darkMode: bool }

type TreeEditData =
    { profileNft: (string * string) option
      nameField: string
      bioField: string
      hasChanges: bool
      linkFields: TreeEditLink[]
      nftFields: Map<string * string, bool>
      colors: string[]
      darkMode: bool }

type AccountData =
    { selectedAccount: string
      profileInfo: ProfileInfo option option
      chainId: int }

type ProfileUpdate =
    { address: string
      ens: string
      text: string }

type CommonModel =
    { accountData: AccountData option }

type ChainConfig =
    { infuraUrl: string
      infuraWsUrl: string
      chainId: int
      chainName: string
      web3treeContractAddress: string }

let (|ContainsKey|_|) key map = map |> Map.tryFind key

let isMobile =
    
    match !!Browser.Dom.window?navigator?userAgent with
    | (x :string) when x.Contains("Android") -> true
    | x when x.Contains("webOS") -> true
    | x when x.Contains("iPhone") -> true
    | x when x.Contains("iPad") -> true
    | x when x.Contains("iPod") -> true
    | x when x.Contains("BlackBerry") -> true
    | x when x.Contains("IEMobile") -> true
    | x when x.Contains("Opera Mini") -> true
    | _ -> false

let inline modelViewer props children = domEl "model-viewer" props children

module Emojis =

    let fire = @""

    let emojis =
        ["fire", "emoji_u1f525", "🔥"
         "heart", "emoji_u2764", "❤"
         "smile", "emoji_u1f600", "😃"
         "laugh", "emoji_u1f602", "😂"
         "wink", "emoji_u1f609", "😉"
         "cool", "emoji_u1f60e", "😎"
         "nerd", "emoji_u1f913", "🤓"
         "star-struck", "emoji_u1f60d", "🤩"
         "in-love", "emoji_u1f970", "🥰"
         "crazy", "emoji_u1f92a", "🤪"
         "money", "emoji_u1f911", "🤑"
         "surprised", "emoji_u1f62e", "😲"
         "thumbs-up", "", "👍"
         "100", "", "💯"
         "hands-up", "", "🙌"
         "top", "", "🔝"
         "ghost", "emoji_u1f47b", "👻"
         "alien", "emoji_u1f47d", "👽"
         "space-invader", "", "👾"
         ]

    let picByName = emojis |> List.map (fun (n, c, _) -> n, c) |> Map.ofList

    let charByName = emojis |> List.map (fun (n, _, c) -> n, c) |> Map.ofList

module TreeEditData =

    let toTree (tree :TreeEditData) =
        { profileNft = tree.profileNft
          name = tree.nameField
          bio = tree.bioField
          links = tree.linkFields |> Array.map (fun l -> { name = l.name; url = l.url })
          nfts = tree.nftFields
          colors = tree.colors
          darkMode = tree.darkMode }

module TreeData =

    open System.Text

    let toMarkdown (tree :TreeData) =
        let sb = new StringBuilder()
        match tree.profileNft with
        | Some (address, id) ->
            sb.AppendLine("+ " + address + ", " + id) |> ignore
        | _ -> ()
        let sb =
            sb.Append("#")
              .AppendLine(tree.name)
              .AppendLine(tree.bio)
              .AppendLine("***")
        for link in tree.links do
            sb.AppendLine(sprintf "[%s](%s)" link.name link.url) |> ignore
        let toHide = tree.nfts |> Map.toArray |> Array.map fst |> Array.groupBy fst
        for (address, ids) in toHide do
            sb.AppendLine(sprintf "* %s" address) |> ignore
            for (_, id) in ids do
                sb.AppendLine(sprintf "** %s" (id.ToString())) |> ignore
        match tree.colors with
        | [||] -> ()
        | _ ->
            let cstr = System.String.Join(", ", tree.colors)
            let mstr = if tree.darkMode then "+" else "-"
            sb.AppendLine(sprintf "%s %s" mstr cstr) |> ignore
        sb.ToString()

    let fromMarkdown (markdown :string) =
        let lines = markdown.Split('\n') |> Array.filter (fun l -> not <| System.String.IsNullOrEmpty l)
        // optional profile nft
        let profileNft =
            if lines.[0].StartsWith("+") then
                let split = lines.[0].Substring(1).Split(',')
                Some (split.[0].Trim(), split.[1].Trim())
            else
                None
        let headerLine = match profileNft with | None -> 0 | Some _ -> 1
        let name = lines.[headerLine].TrimStart('#')
        let bioEnd = lines |> Array.findIndex (fun l -> l = "***")
        let bio = System.String.Join("\n", [| (headerLine + 1) .. bioEnd - 1 |] |> Array.map (fun i -> lines.[i]))
        let links =
            lines |> Array.choose (fun l ->
                let regex = RegularExpressions.Regex("""^\[([\w\s\d]+)\]\((https?:\/\/[\w\d./?=#@]+)\)""" )
                let m = regex.Matches(l)
                if m.Count > 0 then Some (m.[0].Groups.[1].Value, m.[0].Groups.[2].Value) else None)
                |> Array.map (fun (n, u) -> { name = n; url = u })
        let rec addHide tokenAddress nfts (lines :string list) =
            match lines with
            | [] -> nfts
            | (l::ls) ->
                if l.StartsWith("**") then
                    let id = l.TrimStart('*').Trim()
                    addHide tokenAddress (nfts |> Map.add (tokenAddress, id) true) ls
                else
                    if l.StartsWith("*") then addHide (l.TrimStart('*').Trim()) nfts ls
                    else addHide tokenAddress nfts ls
        let remaining = lines |> Array.skip (bioEnd + 1) |> Array.toList
        let darkMode, colors =
            let last = lines |> Array.last
            if last.StartsWith("-") || last.StartsWith("+") then
                last.StartsWith("+"), last.Substring(2).Split(',') |> Array.map (fun s -> s.Trim())
            else false, [||]
        { profileNft = profileNft
          name = name
          bio = bio
          links = links
          nfts = addHide "" Map.empty remaining
          colors = colors
          darkMode = darkMode }

module ColorUtils =

    let gradients =
        [["#FF4E50"; "#F9D423"], "Dance To Forget"
         ["#ee0979"; "#ff6a00"], "Ibiza Sunset"
         ["#FC354C"; "#0ABFBC"], "Miaka"
         ["#e14fad"; "#f9d423"], "Juicy Cake"
         ["#FEAC5E"; "#C779D0"; "#4BC0C8"], "Atlas"
         ["#DA22FF"; "#9733EE"], "Intuitive Purple"
         ["#f953c6"; "#b91d73"], "Neuromancer"
         ["#FC466B"; "#3F5EFB"], "Sublime Vivid"
         ["#9796f0"; "#fbc7d4"], "Anamnisar"
         ["#00dbde"; "#fc00ff"], "North Miracle"
         ["#283048"; "#859398"], "Titanium"
         ["#373B44"; "#4286f4"], "Dark Ocean"
         ["#3a6186"; "#89253e"], "Love Couple"
         ["#4ECDC4"; "#556270"], "Disco"
         ["#2193b0"; "#6dd5ed"], "Cool Blues"
         ["#c2e59c"; "#64b3f4"], "Green and Blue"
         ["#16A085"; "#F4D03F"], "Harmonic Energy"
         ["#FA8BFF"; "#2BD2FF"; "#2BFF88"], "Summer Glow"
         ["#434343"; "#000000"], "Premium Dark"
         ["#fdfbfb"; "#ebedee"], "Cloudy Knoxville"
         ]

    let gradientArray =
        gradients |> List.map (fun (cs, n) -> cs |> List.toArray, n) |> Array.ofList

    let defaultColors = [|"#283048"; "#859398"|]

    let fromRgb (r, g, b) =
        let r = float r / 255.0
        let g = float g / 255.0
        let b = float b / 255.0
        let mn = min (min r g) b
        let mx = max (max r g) b
        let d = mx - mn
        let l = (mx + mn) / 2.0
        if d <> 0.0 then
            let s = if l < 0.5 then d / (mx + mn) else d / (2.0 - mx - mn)
            let h = if r = mx then (g - b) / d else if g = mx then 2.0 + (b - r) / d else 4.0 + (r - g) / d
            let h = h * 60.0
            let h = if h < 0.0 then h + 360.0 else h
            h, s, l
        else 0.0, 0.0, l

    let toRgb (h, s, l) =
        let h = h / 60.0
        match s with
        | 0.0 -> let v = int (round (l * 255.0)) in v, v, v
        | _ ->
            let calc c t1 t2 =
                let c = if c < 0.0 then c + 1.0 else if c > 1.0 then c - 1.0 else c
                if 6.0 * c < 1.0 then t1 + (t2 - t1) * 6.0 * c
                    else if 2.0 * c < 1.0 then t2
                        else if 3.0 * c < 2.0 then t1 + (t2 - t1) * (2.0 / 3.0 - c) * 6.0 else t1
            let t2 = if l < 0.5 then l * (1.0 + s) else (l + s) - (l * s)
            let t1 = 2.0 * l - t2
            let th = h / 6.0
            let tr = th + (1.0 / 3.0)
            let tg = th
            let tb = th - (1.0 / 3.0)
            let tr = calc tr t1 t2
            let tg = calc tg t1 t2
            let tb = calc tb t1 t2
            int (round (tr * 255.0)), int (round (tg * 255.0)), int (round (tb * 255.0))

    let fromHexStr (color :string) =
        let r = System.Int32.Parse(color.Substring(1, 2), System.Globalization.NumberStyles.HexNumber)
        let g = System.Int32.Parse(color.Substring(3, 2), System.Globalization.NumberStyles.HexNumber)
        let b = System.Int32.Parse(color.Substring(5, 2), System.Globalization.NumberStyles.HexNumber)
        r, g, b

    let lighten (factor: float) (hexColor :string) =
        let h, s, l = hexColor |> fromHexStr |> fromRgb
        let r, g, b = toRgb (h, (s * (1.0 + factor)), (l * (1.0 + factor)))
        sprintf "rgb(%i, %i, %i)" r g b

    let isLight (colors :string seq) =
        let averageLightness = colors |> Seq.map (fun c -> let _, _, l = c |> fromHexStr |> fromRgb in l) |> Seq.average
        averageLightness > 0.85

    let isDark (colors :string seq) =
        let averageLightness = colors |> Seq.map (fun c -> let _, _, l = c |> fromHexStr |> fromRgb in l) |> Seq.average
        averageLightness < 0.15

    let isSlightlyLight (colors: string seq) =
        let averageLightness = colors |> Seq.map (fun c -> let _, _, l = c |> fromHexStr |> fromRgb in l) |> Seq.average
        averageLightness > 0.4

module Config =

    let private kovan =
      { infuraUrl = "https://kovan.infura.io/v3/2ba89d75124a4e0baf363346d70820fb"
        infuraWsUrl = "wss://kovan.infura.io/ws/v3/2ba89d75124a4e0baf363346d70820fb"
        chainId = 42
        chainName = "Kovan"
        web3treeContractAddress = "0x5f73F01a15efb3311605AFCbB7dDC60055dB941D" }

    let private mainnet =
        { infuraUrl = "https://mainnet.infura.io/v3/2ba89d75124a4e0baf363346d70820fb"
          infuraWsUrl = "wss://mainnet.infura.io/ws/v3/2ba89d75124a4e0baf363346d70820fb"
          chainId = 1
          chainName = "Mainnet"
          web3treeContractAddress = "0x96df6c12aabb9b0c9c968457a9834d9e9769ae51" }

    let network = mainnet

    let alchemyHttps = "https://eth-mainnet.alchemyapi.io/v2/ISwsu9zW_oF4jSNsJ00y6ldFN_rUDf8g"

    let nftPageSize = 6

    let getBlockExplorerUrl txHash =
        match network.chainId with
        | 42 -> "https://kovan.etherscan.io/tx/" + txHash
        | 1 -> "https://etherscan.io/tx/" + txHash

    let siteUrl = "https://web3tr.ee"

// Web3
// ---------------
let getLibrary provider = new Web3Provider(provider)

let Injected :IWeb3Connector = new InjectedConnector({ supportedChainIds = [|Config.network.chainId|] }) :> IWeb3Connector

let CoinbaseWallet :IWeb3Connector = new WalletLinkConnector({ url = Config.network.infuraUrl; supportedChainIds = [|Config.network.chainId|]; appName = "Web3Tree" }) :> IWeb3Connector

let WalletConnect :IWeb3Connector = new WalletConnectConnector({ rpcUrl = Config.network.infuraUrl; bridge = "https://bridge.walletconnect.org"; qrcode = true }) :> IWeb3Connector

let web3treeAbi : obj = import "default" "./web3tree-abi.js"

let web3treeAbiHr :obj =
    let inter = new Interface(web3treeAbi)
    inter.format(formatTypes.full)

// ----------------

let getStorageType (src :string) =
    if src.StartsWith "data:" then OnChain
    else
        if src.StartsWith "ipfs://" then IPFS
        else Unknown

module Moralis =

    open Fable.SimpleHttp
    open Fable.SimpleJson

    type PagedResult<'t> =
        { total: int
          page: int
          page_size: int
          cursor: string
          result: 't[] }

    type ProfileEvent =
        { user: string
          text: string
          block_number: int64 }

    type EventResults<'t> =
        { results: 't[] }

    //let getProfileEvents (account :string) =
    //    let url =
    //        "https://oq4tkdwha9jz.usemoralis.com:2053/server/classes/profile?order=-block_number&limit=1&where=%7B%20%22user%22%20%3A%20%22" + account.ToLower() + "%22%20%7D"
    //    async {
    //        let! response =
    //            Http.request url
    //                |> Http.method GET
    //                |> Http.header (Headers.accept "application/json")
    //                |> Http.header (Headers.create "X-Parse-Application-Id" "mhAmYT6t2GjzJCMI0Jkl6nXAeTDui4x7ZQQXHvfi")
    //                |> Http.header (Headers.create "X-Parse-Master-Key" "G5e2lnbxisjISbb2Qsglib6M4URQ5fJ30zONshpd")
    //                |> Http.send
    //        let results : EventResults<ProfileEvent> = Json.parseAs response.responseText
    //        return results
    //        }

module Server =

    open Fable.SimpleJson
    open Fable.SimpleHttp

    let baseUrl = "https://sociable.network"

    let postReceipt (receipt :obj) =
        async {
            let body = Json.stringify receipt
            let! code, _ = Http.post (baseUrl + "/api/receipt") body
            match code with
            | 200 -> return ()
            | _ -> return failwith "error"
        }

    let getLikes tokenAddress tokenId :Like[] Async =
        async {
            let! code, response = Http.get <| sprintf "%s/api/likes/%s/%s" baseUrl tokenAddress tokenId
            match code with
            | 200 -> return Json.parseAs response
            | _ -> return [||]
        }

    let getProfileInfo address :ProfileInfo option Async =
        async {
            let! code, response = Http.get <| baseUrl + "/api/profileinfo/" + address
            match code with
            | 200 -> return Some (Json.parseAs response)
            | 404 -> return None
            | _ -> return failwith "error"
        }

    let getAddressForEns username =
        async {
            let! code, response = Http.get <| baseUrl + "/api/address/" + username
            match code with
            | 200 -> return Some response
            | 404 -> return None
            | _ -> return failwith "error"
        }

    let getAddressForUrl url =
        async {
            let! code, response = Http.get <| baseUrl + "/api/map/" + url
            match code with
            | 200 -> return Some response
            | 404 -> return None
            | _ -> return failwith "error"
        }

    let getUrlForAddress address =
        async {
            let! code, response = Http.get <| baseUrl + "/api/map/" + address
            match code with
            | 200 -> return Some response
            | _ -> return failwith "error"
        }

    let getEns address =
        async {
            let! code, response = Http.get <| baseUrl + "/api/ens/" + address
            match code with
            | 200 when not <| System.String.IsNullOrEmpty response -> return Some response
            | _ -> return None
        }

    let getColorsForAddress (address :string) =
        let r = System.Int32.Parse(address.Substring(2, 2), System.Globalization.NumberStyles.HexNumber)
        let colors, _ = ColorUtils.gradients |> List.item (r % ColorUtils.gradients.Length)
        let darkMode = r % 2 = 0
        colors |> List.toArray, darkMode

    //let getTreeData address =
    //    async {
    //        let! response = Moralis.getProfileEvents address
    //        match response.results.Length with
    //        | 0 ->
    //            // pick colour scheme at random from address
    //            let colors, darkMode = getColorsForAddress address
    //            return
    //                { TreeData.name = ""
    //                  profileNft = None
    //                  bio = ""
    //                  links = [||]
    //                  nfts = Map.empty
    //                  colors = colors
    //                  darkMode = darkMode }
    //        | _ ->
    //            let latest = response.results |> Array.maxBy (fun e -> e.block_number)
    //            let treeData = latest.text |> TreeData.fromMarkdown
    //            match treeData.colors with
    //            | [||] ->
    //                let colors, darkMode = getColorsForAddress address
    //                return { treeData with colors = colors; darkMode = darkMode }
    //            | _ -> return treeData
    //    }

    let getTreeDataFromServer user =
        async {
            let! code, response = Http.get <| baseUrl + "/api/profileupdate/" + user
            match code with
            | 200 ->
                let profileUpdate :ProfileUpdate = Json.parseNativeAs response
                let tree =
                    if System.String.IsNullOrEmpty profileUpdate.text then
                        let colors, darkMode = getColorsForAddress profileUpdate.address
                        { TreeData.name = ""
                          profileNft = None
                          bio = ""
                          links = [||]
                          nfts = Map.empty
                          colors = colors
                          darkMode = darkMode }
                    else
                        let treeData = profileUpdate.text |> TreeData.fromMarkdown
                        match treeData.colors with
                        | [||] ->
                            let colors, darkMode = getColorsForAddress profileUpdate.address
                            { treeData with colors = colors; darkMode = darkMode }
                        | _ -> treeData
                return Some (profileUpdate.address, profileUpdate.ens, tree)
            | 404 ->
                return None
            | _ -> return failwith "an error occurred getting profile update from server"
        }

    let getNFTs account =
        async {
            let! code, response = Http.get <| baseUrl + "/api/nfts?account=" + account
            match code with
            | 200 ->
                let nfts :PagedResult<NFTCollectionResult> = Json.parseNativeAs response
                return nfts
            | _ -> return { results = [||]; cursor = null }
        }

    let getNFT tokenAddress tokenId =
        async {
            let! code, response = Http.get <| sprintf "%s/api/nft/%s/%s" baseUrl tokenAddress tokenId
            match code with
            | 200 when not <| System.String.IsNullOrEmpty (response) ->
                let nft :NFT = Json.parseNativeAs response
                return Some nft
            | _ -> return None
        }

    let getNFTDetails tokenAddress tokenId =
        async {
            let! code, response = Http.get <| sprintf "%s/api/nftdetails/%s/%s" baseUrl tokenAddress tokenId
            match code with
            | 200 when not <| System.String.IsNullOrEmpty (response) ->
                let nft :NFTResultFull = Json.parseNativeAs response
                return Some nft
            | _ -> return None
        }

    let getNFTOwners tokenAddress tokenId =
        async {
            let! code, response = Http.get <| sprintf "%s/api/nftowners/%s/%s" baseUrl tokenAddress tokenId
            match code with
            | 200 when not <| System.String.IsNullOrEmpty (response) ->
                let nft :NFTOwners = Json.parseNativeAs response
                return Some nft
            | _ -> return None
        }

    let getCollection tokenAddress =
        async {
            let! code, response = Http.get <| sprintf "%s/api/collection/%s" baseUrl tokenAddress
            match code with
            | 200 when not <| System.String.IsNullOrEmpty (response) ->
                let nft :NFTCollection = Json.parseNativeAs response
                return Some nft
            | _ -> return None 
        }

    //let getTreeAndProfileNft address =
    //    async {
    //        let! tree = getTreeData address
    //        let! profileNft =
    //            match tree.profileNft with
    //            | None -> async { return None }
    //            | Some (tokenAddress, tokenId) ->
    //                getNFT tokenAddress tokenId
    //        return tree, profileNft
    //    }

module Validate =

    let linkName txt = if System.String.IsNullOrEmpty txt then false else true

    let linkUrl txt = System.Text.RegularExpressions.Regex.IsMatch(txt, "^(ht|f)tp(s?)\:\/\/[0-9a-zA-Z]([-.\w]*[0-9a-zA-Z])*(:(0-9)*)*(\/?)([a-zA-Z0-9\@\-\.\?\,\'\/\\\+&%\$#_]*)?$")
