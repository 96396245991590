module App.View

open Elmish
open Elmish.Navigation
open Elmish.UrlParser
open Fable.Core.JsInterop
open Types
open App.State
open Global
open Imports
open Browser.Dom
open Fable.React
open Fable.Core
open Web3TreeDapp
open Common
open ViewComponents

// import stylesheet
importAll "../sass/main.sass"

// Dapp Component for executing transactions
type DappProps<'txmsg, 'model, 'msg> =
    { makeTxProcessor :UseWeb3ReactHook -> 'txmsg -> JS.Promise<TransactionResponse>
      model: 'model
      dispatch: 'msg -> unit
      childRender: DappChildProps<'txmsg, 'model, 'msg> -> ReactElement }

and DappChildProps<'txmsg, 'model, 'msg> =
    { model: 'model
      dispatch: 'msg -> unit
      txResult: DappTxResult<'txmsg> option
      txDispatch: 'txmsg -> unit }

let Dapp<'txmsg, 'model, 'msg> props =
    FunctionComponent.Of ((fun (props :DappProps<'txmsg, 'model, 'msg>) ->
        let web3 = useWeb3React()
        let txMsg = Hooks.useState None
        let txProcessor = Hooks.useRef None
        let txResult = Hooks.useState None
        let connectModalActive = Hooks.useState false
        window?wr <- web3
        Hooks.useEffect(fun _ ->
            match web3.active with
            | true ->
                txProcessor.current <- (Some (props.makeTxProcessor web3))
            | _ -> ())
        Hooks.useEffect(fun () ->
            match txMsg.current, txProcessor.current with
            | Some txmsg, Some processor ->
                txMsg.update None
                promise {
                    let! response = processor txmsg
                    txResult.update (Some (Response (txmsg, response)))
                    let! confirmed = response.wait()
                    txResult.update (Some (Confirmed (txmsg, confirmed)))
                } |> Promise.catch (fun e ->
                    if e?code = 4001 then txResult.update (Some (UserCancelled txmsg))
                    else txResult.update (Some (UnknownError (txmsg, e)))
                    ) |> Promise.start
            | _ -> ())
        contextProvider connectModalControlsContext (Some { show = (fun () -> connectModalActive.update true); hide = fun () -> connectModalActive.update false })
            [div []
                [ConnectModal { active = connectModalActive.current }
                 props.childRender
                    { model = props.model
                      dispatch = props.dispatch
                      txResult = txResult.current
                      txDispatch = Feliz.React.useCallbackRef(fun msg -> txMsg.update (Some msg) ) }]]
    ), "Dapp", equalsButFunctions) props

// Page Control
let pageRender props =
    match props.model.page with
    | Home -> Home.View.root props.model.home props.model.common (HomeMsg >> props.dispatch)

// View root
let root model dispatch = 
    web3ReactProvider
        [Web3ReactProviderProps.GetLibrary Common.getLibrary]
        [Dapp<TransactionMsg, Model, Msg> { makeTxProcessor = makeTxProcessor; model = model; dispatch = dispatch; childRender = pageRender }] 

open Elmish.React
open Elmish.Debug
open Elmish.HMR 

let gbl =
    { web3Modal = obj() //web3Modal
      window = window
      web3 = obj()//web3
      web3treeContract = obj() }//contract}

// App
Program.mkProgram (init gbl) (update gbl) root
//|> Program.withSubscription metamaskChangeAccount
//|> Program.withSubscription scrollToBottom
|> Program.toNavigable (parsePath pageParser) (urlUpdate gbl)
#if DEBUG
|> Program.withDebugger
#endif
|> Program.withReactBatched "elmish-app"
|> Program.run
