module Utils

open Fable.Core
open Fable.Core.JsInterop
open Fable.React
open Fable.React.Props
open Common
open Browser.Dom

let onClickDispatch dispatch msg = OnClick (fun _ -> dispatch msg)

let mdiIcon name = i [ClassName <| "mdi mdi-" + name] []

let mdiIconLrg name = i [ClassName <| "mdi mdi-24px mdi-" + name] []

let getMimeType format =
    match format with
    | "mp4" -> "video/mp4"
    | _ -> "unknown"

let emoji emoji = span [ClassName "emoji"] [str emoji]

let emojisFromText (text :string) =
    text.Split(' ') |> Array.choose (fun name ->
        Emojis.charByName |> Map.tryFind name |> Option.map (fun c -> span [ClassName <| "emoji emoji-" + name] [str c]))
            |> Array.toList

let strf string = str (sprintf string)

let niceAddress (address :string) =
    address.Substring(0, 6) + " ... " + address.Substring(36)

let niceTimestamp (timestamp :int64) =
    let date = System.DateTimeOffset.FromUnixTimeSeconds(timestamp).DateTime
    let now = System.DateTime.UtcNow
    let ago = (now - date)
    match ago with
    | _ when ago.TotalMinutes < 1.0 -> sprintf "%is" (int ago.TotalSeconds)
    | _ when ago.TotalHours < 1.0 -> sprintf "%im" (int ago.TotalMinutes)
    | _ when ago.TotalDays < 1.0 -> sprintf "%ih" (int ago.TotalHours)
    | _ when ago.TotalDays < 7.0 -> sprintf "%id" (int ago.TotalDays)
    | _ -> sprintf "%iw" (int (ago.TotalDays / 7.0))

let isNullOrEmpty = System.String.IsNullOrEmpty

let jazziconForAddress width (address :string) =
    let seed = System.Int32.Parse(address.Substring(2, 8), System.Globalization.NumberStyles.HexNumber)
    Imports.ReactJazzicon.jazzicon [Imports.ReactJazzicon.Diameter width; Imports.ReactJazzicon.Seed seed] []

let bigLoading = div [ClassName "big-loading"] [div [ClassName "lds-dual-ring"] []]

let errorView = div [ClassName "error-view"] [str "Something went wrong, please try again later"]

let getSimpleGradient (hexColors :string[]) = sprintf "linear-gradient(to right, %s)" (System.String.Join(", ", hexColors))

let getBackToBackGradient (hexColors :string[]) =
    let colors = Array.append hexColors (hexColors |> Array.truncate (hexColors.Length - 1) |> Array.rev)
    getSimpleGradient colors

let getGradientBackgroundStyle (hexColors :string[]) isDarkMode className =
    let color2 = hexColors.[0] |> ColorUtils.fromHexStr
    let color1 = hexColors |> Array.last |> ColorUtils.fromHexStr
    let (bh, bs, _) = color2 |> ColorUtils.fromRgb
    let backgroundColor = ColorUtils.toRgb (bh, bs * 0.3, if isDarkMode then 0.1 else 0.97)
    let linearGradient direction color1 start1 color2 start2 =
        let (r1, g1, b1, a1) = color1
        let (r2, g2, b2, a2) = color2
        sprintf "linear-gradient(%s, rgba(%i, %i, %i, %f) %f%%, rgba(%i, %i, %i, %f) %f%%)"
            direction r1 g1 b1 a1 start1 r2 g2 b2 a2 start2
    let radialGradient (x, y) color1 start1 color2 start2 =
        let (r1, g1, b1, a1) = color1
        let (r2, g2, b2, a2) = color2
        sprintf "radial-gradient(ellipse at %f%% %f%%, rgba(%i, %i, %i, %f) %f%%, rgba(%i, %i, %i, %f) %f%%)"
            x y r1 g1 b1 a1 start1 r2 g2 b2 a2 start2
    let (rback, gback, bback) = backgroundColor
    let (r1, g1, b1) = color1
    let (r2, g2, b2) = color2
    let fadeBottom = linearGradient "to bottom" (rback, gback, bback, 1.0) 0.0 (255, 255, 255, 0.0) 90.0
    let fadeTop = linearGradient "to top" (rback, gback, bback, 1.0) 0.0 (255, 255, 255, 0.0) 90.0
    let bro, tlo =
        if isDarkMode then 0.65, 0.35 else 0.3, 0.35
    let radBotRight = radialGradient (100.0, 75.0) (r1, g1, b1, bro) 0.0 (rback, gback, bback, 0.2) 85.0
    let radTopLeft = radialGradient (0.0, 25.0) (r2, g2, b2, tlo) 0.0 (rback, gback, bback, 0.3) 65.0
    let backgroundColorStr = sprintf "rgb(%i, %i, %i)" rback gback bback
    let centerFade = if isDarkMode then "rgba(22, 22, 22, 0.9)" else "rgba(244, 244, 244, 0.9)"
    style [] [str (sprintf ".%s { background-size: 100vw 100vh; background-color: %s; background-image: linear-gradient(to right, rgba(0, 0, 0, 0) 25%%, %s 40%%, %s 60%%, rgba(0, 0, 0, 0) 75%%), %s, %s, %s, %s; background-blend-mode: normal, normal, normal, normal, normal }" className backgroundColorStr backgroundColorStr centerFade fadeBottom fadeTop radBotRight radTopLeft)]

let getFormatName =
    function
    | "svg" -> "SVG"
    | "png" -> "PNG"
    | "gif" -> "GIF"
    | "jpg" -> "JPEG"
    | "webp" -> "WebP"
    | _ -> "Image"
let getStorageName =
    function
    | "onchain" -> "On-chain"
    | "ipfs" -> "IPFS"
    | _ -> "Server"
let getVideoFormatName =
    function
    | "mp4" -> "MP4"
    | _ -> "Video"

let getMediaText =
    function
    | { display = "image"; storage = "onchain" } -> " On-chain Image"
    | { display = "image"; format = _; storage = "ipfs" } -> " Image on IPFS"
    | { display = "video"; format = _; storage = "ipfs" } -> " Video on IPFS"
    | { display = "video" } -> "Video"
    | { display = "image" } -> "Image"
    | _ -> ""

let getMediaElement display =
    let txt = getMediaText display
    match display with
    | { storage = "onchain" } ->
        [span [ClassName "emoji mode-aware-text"] [str "🔗"]; span [] [str txt]]
    | { storage = "ipfs" } ->
        [span [ClassName "emoji mode-aware-text"] [str "🌎"]; span [] [str txt]]
    | _ -> [span [] [str txt]]

let useScrollTo () =
    let ref :IRefValue<Browser.Types.Element option> = Hooks.useRef None
    let shouldScrollTo = Hooks.useState false
    Hooks.useEffect(fun () ->
        match ref.current with
        | Some element when shouldScrollTo.current ->
            element?scrollIntoView()
            shouldScrollTo.update(false)
        | _ -> ())
    ref, fun () -> shouldScrollTo.update(true)
